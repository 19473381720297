<template>
    <div class="vote-form">
        <div class="vote-form-content">
            <div class="form-bg">
                <img src="../../static/image/vote/list_01.png"/>
                <img src="../../static/image/vote/list_02.png"/>
                <img src="../../static/image/vote/list_03.png"/>
                <div class="goods-list">
                    <img src="../../static/image/vote/list_04.png"/>
                    <div class="list-content">
                        <div class="list-item" v-for="(item, index) in list" :key="index" @click="changeSelect(index)">
                            <img :src="getItemSrc(index)"/>
                        </div>
                    </div>
                </div>
                <div class="form-desc">
                    <img src="../../static/image/vote/list_05.png"/>
                    <div class="desc-content">
                        <p class="desc-title">注意事项：</p>
                        <p>1. 本次投票为多选，如建议其他产品，请给我们留言吧！</p>
                        <p>2. 征集时间截止至1月30日</p>
                    </div>
                </div>

            </div>
            <div class="vote-btn" @click="userVote">
                <img :src="require('../../static/image/vote/btn'+ (finished ? '_ytp' : '') +'.png')"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {Toast} from 'vant'
    import {userLogin, userVoteApi, voteHistoryApi} from "../../server";
    import core from "../../core";
    import initWXAPI from "../../utils/initWXAPI";
    import WXShare from "../../utils/WXShare";
    import getStrParams from "../../utils/getStrParams";

    Vue.use(Toast)
    export default {
        name: "Form",
        data() {
            return {
                list: new Array(12),
                sessionId: '',
                code: '',
                activeItems: [],
                finished: true,
                loading: false
            }
        },
        methods: {
            getItemSrc(index) {
                // if(index == 0)console.log(this.activeItems,this.activeItems.find(item => item == index))
                return require('../../static/image/vote/icon_' + (index >= 9 ? index + 1 : ('0' + (index + 1))) + (this.activeItems.find(item => item == index) === undefined ? '.png' : 'h.png'))
            },
            changeSelect(index) {
                if(this.finished)return;
                if (this.activeItems.find(item => item == index) !== undefined) {
                    this.activeItems.splice(this.activeItems.findIndex(item => item == index), 1)
                } else {
                    this.activeItems.push(index);
                }
                console.log(this.activeItems)
            },
            async userVote() {
                if(this.finished){
                    Toast('您已经投过票了');
                    return;
                }
                if (this.activeItems.length == 0) {
                    Toast('请先选择投票内容');
                    return;
                }
                if(this.loading)return;
                this.loading = true;
                const data = await userVoteApi({
                    SessionId: this.sessionId,
                    VIds: this.activeItems.join(',')
                });
                if (data.Data) {
                    Toast(data.Data.Msg || '投票成功');
                    this.finished = true;
                    this.loading = false;
                } else {
                    Toast(data.ErrorMsg);
                }
            },
            async getUserHistoryVote() {
                const data = await voteHistoryApi({SessionId: this.sessionId});
                const d = data.Data;
                if (d && d.list && d.list.length) {
                    this.finished = true;
                    Toast('您已经投过票了');
                    d.list.forEach(d => {
                        this.activeItems.push(d.VId);
                    })
                } else {
                    this.finished = false;
                }
            },
            initPageShare() {
                initWXAPI.init(this.$store.state.url,function(){
                    const options = {
                        title: '红色故事演讲大赛',
                        desc:  '2022上海市民文化节',
                        imgUrl: 'https://redsw1.02lu.com/share_logo.png'
                    }
                    WXShare.initWXShare(options)
                    WXShare.initWXTimelineShare(options)
                })
            },
            async getUserInfo() {
                const data = await userLogin({Code: this.code, Session:''})
                this.sessionId = data.Data.SessionId;
                this.getUserHistoryVote();
            }
        },
        created() {
            this.initPageShare();
            // this.sessionId = core.cookie.getStorage('sessionId');
            this.code = window.location.search && getStrParams.getStrParams(window.location.search.split('?')[1]).code;
            this.getUserInfo();
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/index";

    .vote-form {
        min-height: 100vh;

        .vote-form-content {
            .form-bg {
                font-size: 0;

                img {
                    width: 100%;
                }
            }

            .goods-list {
                position: relative;

                .list-content {
                    position: absolute;
                    top: 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 0.5rem 2rem 0;

                    .list-item {
                        flex: 1;
                        width: 31%;
                        min-width: 31%;
                        max-width: 31%;
                        box-sizing: border-box;
                        padding-bottom: 3%;
                    }
                }
            }

            .form-desc {
                position: relative;

                .desc-content {
                    position: absolute;
                    top: 0;
                    width: 80%;
                    left: 10%;
                    padding-top: toRem(30);
                    color: #ccc;
                    .desc-title{
                        font-size: toRem(26);
                    }
                    p{
                        font-size: toRem(22);
                        line-height: 1.8;
                    }
                }
            }

            .vote-btn {
                position: fixed;
                bottom: 1rem;
                left: 10%;
                width: 80%;

                img {
                    width: 100%;
                }
            }
        }
    }
</style>